@font-face {
    font-family: home-caption;
    src: url(./assets/impact.8fc622c3a2e2d992ec05.ttf) format("truetype");
}

@font-face {
    font-family: home-tag;
    src: url(./assets/regular.6b9430d2297d24dc8fec.woff)
}

body {
    margin: 0;
    padding: 0;
}

*,
:after,
:before {
    box-sizing: border-box;
}

tas-text-color {
    color: '#004090' !important;
}

.swiper-button-next,
.swiper-button-prev {
    color: #fff !important;
}

@media only screen and (min-width: 230px) and (max-width: 720px) {
    .leaflet-tile-container img {
        width: 255.7px !important;
        height: 255.9px !important;
    }
}